import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import { PeaceWall, ShankillRoad, FallsRoad, CrumlinRoadGaol } from '../../components/Tours/Locations'
import { ContentWrapper, InfoWithImageRight, InfoWithImageLeft, SVGPattern } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const BelfastPoliticalTour = ({ location }) => {
  const {
    seoImg,
    murals,
    crumlinroad,
    peacewallbelfast
  } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "belfast-murals.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      murals: file(relativePath: { eq: "belfast-murals.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      peacewallbelfast: file(relativePath: { eq: "peace-wall-belfast.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      crumlinroad: file(relativePath: { eq: "crumlin-road-gaol-2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Belfast Political Tour | Belfast Murals, Crumlin Road Gaol, Courthouse"
        description="Learn all about the troubles in Belfast, what is was like to live through, what happened during the troubles and what it’s like now since the peace process."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={murals.childImageSharp.fluid}
        imageAlt="Belfast Peace Wall"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Belfast Political
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Learn all about the troubles in Belfast, what is was like to live through, what happened during the troubles and what it’s like now since the peace process.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>

        <SectionTitle 
          title="Belfast’s Chequered and Troubled Past"
          text={<>Tour in comfort with cost effective sightseeing as you learn the history of Belfast's political past as we visit the Belfast Peace Wall and surrounding areas in one of Chauffeur Me's <InlineLink to="/our-fleet">luxury fleet</InlineLink> of vehicles.</>}
        />

        <InfoWithImageRight
          image={peacewallbelfast.childImageSharp.fluid}
          imageAlt="Belfast Peace Wall Murals"
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            History of The Troubles
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            Discover the real story you won't get from history books as you hear about this turbulent period of modern history in Belfast.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Unknown to most, modern day Belfast remains a divided city. Forty kilometres of a physical wall, up to ten meters high in some locations separates two religious communities living side by side.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
          For more than thirty years, the controversial time known as The Troubles has had a powerful impact on the city and its residents.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Your Chauffeur Me driver will take you to the most historically important and interesting sites.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={crumlinroad.childImageSharp.fluid}
          imageAlt="Inside Crumlin Road Gaol"
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Understand the Stories
                </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            Belfast’s mural’s depict life and the views of both Falls Road and Shankill Road residents.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Explore up close, on foot, these world-famous murals that depict the social, political and cultural history of the city that earns its reputation as the largest, outdoor art gallery in the world and understand the stories behind them.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            As well as the Murals and Peace Wall, we cover Crumlin Road Jail and Court House. Witness to killings, bombings and escape attempts, These Victorian buildings have been a major part of Irish history for over 160 years.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Enjoy the Belfast Political Tour in the comfort of our luxury fleet of vehicles as you revel in the knowledge of our friendly and experienced drivers who are there for you every step of the way.
          </p>
        </InfoWithImageLeft>

      </ContentWrapper>

      <OffsetGridWrapper title="Belfast Political Tour Locations">
        <ShankillRoad />
        <FallsRoad />
        <PeaceWall />
        <CrumlinRoadGaol />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default BelfastPoliticalTour